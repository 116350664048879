@use "@scss/global.scss" as *;

.staking-modal {
  width: 940px;
  padding: 0px;

  .wrapper {
    height: 100%;
    display: flex;
  }

  .divider {
    height: 1px;
    width: calc(100% + 80px);
    margin-left: -40px;
    margin-top: 30px;
    background-color: $white-7;
  }

  .date-details {
    width: 400px;
    padding: 40px;
    padding-top: 60px;

    &-title {
      height: 19px;
    }

    .caption-text {
      font-size: 13px;
      white-space: pre-line;
    }

    .tos-link {
      color: $blue-1;
      font-size: 13px;
    }
  }

  .details {
    width: 540px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 40px;
    box-shadow: 0px 46.809px 70.213px 0px rgba(0, 0, 0, 0.06), 0px 11.702px 23.404px 0px rgba(0, 0, 0, 0.04),
      0px 0px 2.926px 0px rgba(0, 0, 0, 0.04);
    text-overflow: ellipsis;

    .title {
      min-height: 39px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
